<template>
  <b-row>
    <b-col md="12">
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>
          <!-- input search -->
          <div class="row d-flex justify-content-between mb-1">
            <div class="col-md-12 d-flex justify-content-end">
              <b-button
                variant="primary"
                @click="clearFilter()"
              >Clear Filter</b-button>
            </div>
            <!-- choose drivers -->
            <div class="col-md-4">
              <b-form-group class="w-100">
                <label for="select-driver">Choose drivers</label>
                <v-select
                  id="select-driver"
                  v-model="selectedDriver"
                  multiple
                  :options="drivers"
                  placeholder="Driver Name"
                  class="w-100"
                />
              </b-form-group>
            </div>

            <!-- choose start date of payment -->
            <div class="col-md-4">
              <b-form-group class="w-100">
                <label for="start-datepicker">Choose a start date</label>
                <b-form-datepicker
                  id="start-datepicker"
                  v-model="start"
                  class="mb-1"
                />
              </b-form-group>
            </div>

            <!-- choose end date of payment -->
            <div class="col-md-4">
              <b-form-group class="w-100">
                <label for="end-datepicker">Choose a end date</label>
                <b-form-datepicker
                  id="end-datepicker"
                  v-model="end"
                  class="mb-1"
                />
              </b-form-group>
            </div>
          </div>

          <!-- table -->
          <sequence-table
            :rows="rows"
          />
        </b-card-body>
      </b-card-code>

    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BCardBody, BFormGroup, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import * as facilityService from '@/services/facility'
import SequenceTable from './SequenceTable.vue'

export default {
  components: {
    BCardCode,
    BCardBody,
    BRow,
    BCol,
    BFormDatepicker,
    BFormGroup,
    BButton,
    vSelect,
    SequenceTable,
  },

  data() {
    return {
      rows: [],
      drivers: [],
      selectedDriver: null,
      start: null,
      end: null,
    }
  },

  watch: {
    selectedDriver() {
      this.getSequences()
    },

    start() {
      this.getSequences()
    },

    end() {
      this.getSequences()
    },
  },

  mounted() {
    this.getSequences()
  },

  methods: {
    /** get sequence lists */
    getSequences() {
      const payload = {
        drivers: this.selectedDriver,
        start: this.start,
        end: this.end,
      }
      this.$store.commit('set_loading_action', true)
      facilityService.sequences({ payload })
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.rows = res.data.sequences
          this.drivers = res.data.drivers.map(item => item.driver_name)
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('set_loading_action', false)
        })
    },

    /** clear filter value */
    clearFilter() {
      this.selectedDriver = null
      this.start = null
      this.end = null
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
table {
  font-size: 14px;
}
.vs__dropdown-toggle {
  border: 1px solid #ebe9f1;
  max-height: 70px;
  overflow: auto;
}
</style>
